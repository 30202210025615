import { FC, useEffect, useState } from "react";
import { theme, Typography, Divider, Space, Switch } from "antd";

import { useMetricStore, useSettingsStore } from "store";
import PageWrapper from "../../components/PageWrapper";

import { KpiPageHighlightGrid } from "components/Metrics/KpiPageHighlightGrid";
import { MetricLayoutIdentifier } from "types";
import EdgeCards from "./EdgeCards";
import EdgeList from "./EdgeList";
import EdgeMap from "./EdgeMap";
import { VideoPlayer } from "components/VideoPlayer";
import { QuickLinkComponent, QuickLinkMetaMap } from "types/quicklinks";

const { Title } = Typography;

enum ViewType {
  Map = "map",
  List = "list",
  Grid = "grid"
}

export const Edges: FC = () => {
  const { token } = theme.useToken();
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const [viewType, setViewType] = useState<ViewType>(ViewType.List);
  const lightMode = useSettingsStore((state) => state.lightMode);

  const {
    metrics,
    fetchMetricsForLayout,
  } = useMetricStore((state) => ({
    metrics: state.metrics,
    fetchMetricsForLayout: state.fetchMetricsForLayout,
  }));

  useEffect(() => {
    const asyncUseEffect = async () => {
      fetchMetricsForLayout(MetricLayoutIdentifier.MetricLayoutEdges);
    };
    asyncUseEffect();
  }, []);
        
  return (
    <>
      <PageWrapper
        highlights={
          <KpiPageHighlightGrid key="apps" metrics={metrics && metrics.get(MetricLayoutIdentifier.MetricLayoutEdges)} />
        }
        content={
          <div style={{ margin: token.margin, width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                height: "60px",
                paddingRight: token.padding,
                alignItems: "center",
              }}
            >
              <Title level={3}>Edges</Title>
              <Space>
                <Switch
                  size="default" 
                  style={{ marginTop: "80px" }}
                  defaultChecked={true}
                  checkedChildren="List"
                  unCheckedChildren="Map"
                  onChange={(checked) => {
                    checked ? setViewType(ViewType.List) : setViewType(ViewType.Map);
                  }}
                />
                <div style={{ position: "sticky", marginTop: token.margin, height:"90px", width: "200px", border: "solid", borderColor: token.colorPrimary}}>
                  <VideoPlayer
                    videoUrl={QuickLinkMetaMap.get(QuickLinkComponent.Edges)?.url as string}
                    thumbnailUrl={QuickLinkMetaMap.get(QuickLinkComponent.Edges)?.thumbnail as string}
                    playIconSize="small"
                    pipMode={true}
                  />
                </div>
              </Space>
            </div>   
            {useDivider && <Divider />}
            {(viewType == ViewType.Map) && <EdgeMap key={viewType}/>}
            {(viewType == ViewType.List) && <EdgeList key={viewType}/>}
            {(viewType == ViewType.Grid) && <EdgeCards key={viewType}/>}
          </div>
        }
      />
    </>
  );
};

export default Edges;