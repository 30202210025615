import { FC, useEffect, useState } from "react";
import {
  Descriptions,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Switch,
  Typography,
  theme,
} from "antd";

import {
  WarningOutlined
} from "@ant-design/icons";

import Modal from "components/Modal";

const { TextArea } = Input;
const { Option } = Select;

import {
  ApprovalsRequiredFrom,
  IODataType,
  PublishWorkflowModalProps,
  Workflow,
  WorkflowApprovalInfo,
  WorkflowApprover,
} from "types";
import { FieldLabel } from "components/FieldLabel";
import { useRbacStore, useWorkflowStore } from "store";
import { HLink } from "components/Link";

const { Text } = Typography;

const PublishWorkflowModal: FC<PublishWorkflowModalProps> = ({
  open,
  onClose,
  onSubmit,
  loader
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [approvers, setApprovers] = useState<string[]>([]);
  const [approvalRequired, setApprovalRequired] = useState<boolean>(false);
  const [approvalsRequiredFrom, setApprovalsRequiredFrom] = useState<ApprovalsRequiredFrom>(ApprovalsRequiredFrom.All);
  const [approvalTimeoutDays, setApprovalTimeoutDays] = useState<number>(1);
  const [approvalTimeoutHours, setApprovalTimeoutHours] = useState<number>(0);
  const [approvalTimeoutMinutes, setApprovalTimeoutMinutes] = useState<number>(0);
  const users = useRbacStore((state) => state.users);
  
  const {
    selectedWorkflow,
    setActiveNodeCardId,
  } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    setActiveNodeCardId: state.setActiveNodeCardId,
  }));

  useEffect(() => {
    form.setFieldsValue({
      approvalRequired: false,
      approvals_required_from: ApprovalsRequiredFrom.All,
    });
  }, []);

  return (
    <Modal
      loader={loader}
      title="Publish Workflow"
      open={open}
      footerName="Publish"
      onClose={onClose}
      onSubmit={() => 
        form
          .validateFields()
          .then((values) => {
            const workflow = {
              comments: values.comments as string
            } as Workflow;
            if (approvalRequired) {
              workflow.approvalInfo = {
                requiredFrom: approvalsRequiredFrom,
                timeout:  approvalTimeoutDays * 24 * 60 + approvalTimeoutHours * 60 + approvalTimeoutMinutes,
              } as WorkflowApprovalInfo;

              workflow.approvalInfo.approvers = approvers.map((approver) => ({
                id: approver,
                email: users[approver]?.email,
              } as WorkflowApprover));
            }
            onSubmit(workflow);
          })
          .catch((err) => {
            console.log(err);
          })
      }>
        <Space direction="vertical" style={{ display: "flex" }}>
          {selectedWorkflow?.parameters?.length > 0
            &&
            <Space direction="vertical" style={{ display: "flex" }}>
              <FieldLabel label={"Workflow Parameters"} />
              <Descriptions
                size="small"
                bordered
                column={1}
              >
                {selectedWorkflow?.parameters?.map((parameter) => 
                  <Descriptions.Item key={parameter.referenceName} label={parameter.name}>
                    {parameter.type == IODataType.Boolean
                      ?
                      <Text>{parameter.value ? 'True' : 'False'}</Text>
                      :
                      <Text>{parameter.value}</Text>
                    }
                  </Descriptions.Item>
                )}
              </Descriptions>
              <Space style={{ marginBottom: token.margin }}>
                <WarningOutlined style={{ color: token.colorWarning }}/>
                <Space size={3}>
                  <Text type="warning">Review and update workflow parameters </Text>
                  <HLink
                    onClick={() => {
                      onClose();
                      setActiveNodeCardId("workflow");
                    }}
                    text={<Text underline type="secondary">here</Text>}
                  />
                </Space>
              </Space>
            </Space>
          }
          <Form 
            form={form} 
            layout="vertical" 
            name="publishWorkflowForm" 
            autoComplete="off"
          >
            <Form.Item  name="approval_required" label={<FieldLabel label={"Approval Required"} />}>
              <Switch 
                checked={approvalRequired}
                onChange={setApprovalRequired}
              />
            </Form.Item>
            {approvalRequired &&
              <Form.Item 
                name="approvers"
                required
                label={<FieldLabel label={"Approvers"} help={"Approvers list"} />}
                rules={[
                  { required: true, message: `Approvers must be selected!`}
                ]}
                validateTrigger="onSubmit"
              >
                <Select
                  showAction={["focus", "click"]}
                  placeholder={`Select user`}
                  allowClear
                  mode="multiple"
                  onChange={setApprovers}
                >
                  {Object.keys(users)
                    .map((userId) => (
                      <Option key={userId} value={userId}>
                        {users[userId].firstName} {users[userId].lastName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            }
            {approvalRequired &&
              <Form.Item 
                name="approvals_required_from"
                label={<FieldLabel label={"Approvals Required From"} help={"Required approvals from all approvals or first approval from aprovers"} />}
                rules={[
                  { required: true, message: `Approvals required from is required!`}
                ]}
                validateTrigger="onSubmit"
              >
                <Radio.Group
                  defaultValue={ApprovalsRequiredFrom.All}
                  value={approvalsRequiredFrom}
                  onChange={(e: RadioChangeEvent) => setApprovalsRequiredFrom(e.target.value)}
                >
                  <Radio.Button value={ApprovalsRequiredFrom.All}>All</Radio.Button>
                  <Radio.Button value={ApprovalsRequiredFrom.Any}>Any</Radio.Button>
                </Radio.Group>
              </Form.Item>
            }
            {approvalRequired &&
              <Form.Item 
                name="timeout"
                required
                label={<FieldLabel label={"Maximum Time To Wait"} help={"Max time to wait for approval"} />}
                validateTrigger="onSubmit"
              >
                {
                  <Space style={{ display: "flex" }}>
                    <Select
                      style={{ width: 55 }}
                      value={approvalTimeoutDays}
                      onSelect={setApprovalTimeoutDays}
                      options={Array.from({ length: 8 }, (_, i) => ({ value: i }))}
                    />
                    {"Days"}
                    <Select
                      style={{ width: 60 }}
                      value={approvalTimeoutHours}
                      onSelect={setApprovalTimeoutHours}
                      options={Array.from({ length: 24 }, (_, i) => ({ value: i }))}
                    />
                    {"Hours"}
                    <Select
                      style={{ width: 60 }}
                      value={approvalTimeoutMinutes}
                      onSelect={setApprovalTimeoutMinutes}
                      options={Array.from({ length: 60 }, (_, i) => ({ value: i }))}
                    />
                    {"Minutes"}
                  </Space>
                }
              </Form.Item>
            }
            <Form.Item  name="comments" label="Comments" rules={[{ required: true, message: "Comments are required!" }]}>
              <TextArea placeholder="Enter comments" showCount maxLength={100} rows={3}/>
            </Form.Item>
          </Form>
        </Space>
      </Modal>
  );
};

export default PublishWorkflowModal;
